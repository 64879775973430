import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import style from "./Testomonial.module.css";
import StarIcon from "@mui/icons-material/Star";
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";

const testimonials = [
  {
    text: "My initial purchase, and I was thoroughly impressed with the packaging and delivery of the mangoes. Each one was packed with care and delivered promptly. The taste of each mango was delightful, and the quality exceeded my expectations. Highly recommended.",
    name: "Venkatesh P.",
  },
  {
    text: " I consistently admire the exceptional service provided by Devgad Hapoos. Their efficiency, promptness, and warm demeanor never fail to impress. Mangoes are packed meticulously and dispatched in perfect condition. The flavor is exquisite, and the packaging is top-notch.",
    name: "Kedar K.",
  },
  {
    text: "I recently bought 3kg of Banganapalli mangoes from your store, and I must say, the quality was exceptional. My family thoroughly enjoyed every bite of these mangoes, and we're eagerly anticipating the next season.",
    name: "Kalyani S.",
  },
  {
    text: "Devgad Hapoos consistently delivers responsive, punctual, and courteous service, showcasing a high level of professionalism. I wholeheartedly endorse them without any reservation.",
    name: "Tushar S.",
  },
  {
    text: "Absolutely fantastic! I'm truly impressed by the outstanding quality and professional service. Hats off to the wonderful farmers behind this remarkable produce! Three cheers to their dedication and hard work.",
    name: "Ankita D.",
  },
  {
    text: "These mangoes are budget-friendly, and I adore their flavor. When selected ripe, they are simply delicious and complement any dish perfectly. They stand out on their own or pair excellently with a variety of foods.",
    name: "Aniket V.",
  },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Testomonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const step = isMobile ? 1 : 3; // Set step to 1 for mobile, 3 for desktop
  const maxIndex = isMobile ? testimonials.length : Math.ceil(testimonials.length / 3) - 1;

  const desktopSlides = Math.ceil(testimonials.length / step);

  const thumbnails = Array.from({ length: desktopSlides }, (_, index) => index);

  return (
    <div>
      <Grid container spacing={2} className={style.testmaincon}>
        <Grid item xs={12} md={12}>
          <div className={style.testheading}>OUR HAPPY CUSTOMERS</div>
        </Grid>
        <Grid item xs={12} md={12}>
          <AutoPlaySwipeableViews
            index={activeIndex}
            onChangeIndex={(index) => setActiveIndex(index)}
            enableMouseEvents
            interval={5000}
            slideStyle={{ padding: '0 8px' }} 
            slideClassName={style.mobileSlide} 
          >
            {[...Array(desktopSlides)].map((_, idx) => (
              <div key={idx}>
                <Grid container spacing={2} className={style.testsubcon}>
                  {[...Array(step)].map((_, k) => {
                    const dataIndex = idx * step + k;
                    if (dataIndex < testimonials.length) {
                      const item = testimonials[dataIndex];
                      return (
                        <Grid key={k} item xs={12} md={4}>
                          <Paper elevation={3} className={style.testpaper}>
                            <div className={style.startcontainer}>
                              {[...Array(5)].map((_, j) => (
                                <StarIcon key={j} style={{ color: "#ffc34d", fontSize: "22px" }} />
                              ))}
                            </div>
                            <div className={style.testomonialtxt}>{item.text}</div>
                            <div className={style.namecontainer}>
                            <div className={style.testnametxt}>{item.name}</div>
                            <div >
                            <FormatQuoteOutlinedIcon style={{ fontSize: "70px", fontWeight: 100, color: "#e6f9ff" }} />
                            </div>
                            </div>
                          </Paper>
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={style.thumbnailContainer}>
            {thumbnails.map((index) => (
              <div
                key={index}
                className={`${style.thumbnail} ${activeIndex === index ? style.activeThumbnail : ""}`}
                onClick={() => handleThumbnailClick(index)}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Testomonial;
