import { Button, Grid, Paper, TextField } from '@mui/material'
import React from 'react'
import style from "./AdminLogin.module.css"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import InputAdornment from "@mui/material/InputAdornment";
import img from "../../Assets/mango.png"


const AdminLogin = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div>
        <Grid container spacing={0} className={style.adminlogmain}>
            <Grid item xs={12} md={4} className={style.subconainer}>
              <Paper elevation={2} className={style.loginpaper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} mt={2} className={style.logomaincon}>
                  <div className={style.logocontainer}>
                <div className={style.logocon}>
                  <img src={img} className={style.logosty}/>
                </div>
                  <div><span className={style.nametxt1}>Mavla</span><span className={style.nametxt2}>Mangoes</span></div>
                </div>
                  </Grid>
                  <Grid item xs={12} md={12} mb={2}>
                    <div className={style.loginheading}>
                      Login
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                  <TextField
                id="outlined-disabled"
                label="Mobile Number"
                fullWidth
                size="small"
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
                  </Grid>
                  <Grid item xs={12} md={12}>
               <TextField
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                fullWidth
                size="small"
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockPersonIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2} className={style.btnposition}>
                  <Button
                className={style.sendbtnconn}
                variant="contained"
                size="small"
                type='button'
              >
                Sign In
              </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
        </Grid>
    </div>
  )
}

export default AdminLogin