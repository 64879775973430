import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Appbar from "../Appbar/Appbar";
import Banner from "../Banner/Banner";
import ProductCard from "../Product/ProductCard";
import Footer from "../Footer/Footer";
import HomePageDesign from "./HomePageDesign";
import Testomonial from "../Testomonial/Testomonial";
import IconComponenet from "./IconComponenet";
import style from "./Home.module.css";
import VideoComponent from "../VideoComponent/VideoComponent";

const HomePage = () => {
  const [showHomePageDesign, setShowHomePageDesign] = useState(false);
  const [showTestimonial, setShowTestimonial] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const thresholdHomePageDesign = 400;
      const thresholdTestimonial = 800;

      if (offset > thresholdHomePageDesign && !showHomePageDesign) {
        setShowHomePageDesign(true);
      }
      if (offset > thresholdTestimonial && !showTestimonial) {
        setShowTestimonial(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showHomePageDesign, showTestimonial]);

  return (
    <div>
      <Grid container spacing={0} className={style.homecontainer}>
        <Grid item xs={12} md={12}>
          <Appbar />
        </Grid>
        <Grid item xs={12} md={12}>
          <Banner />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <ProductCard />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <div
            className={`${style.homepagedesign} ${
              showHomePageDesign ? style.appear : ""
            }`}
          >
            {" "}
            {/* Use styles.homepagedesign and styles.appear */}
            <HomePageDesign />
          </div>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <div
            className={`${style.testimonial} ${
              showTestimonial ? style.appear : ""
            }`}
          >
            <Testomonial />
          </div>
        </Grid>

        <Grid item xs={12} md={12} mt={2}>
          <Footer />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <IconComponenet />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
