import { Grid } from '@mui/material'
import React, { useRef, useState } from 'react'
import Footer from '../Footer/Footer'
import style from "./About.module.css"
import Appbar from '../Appbar/Appbar'
import Slider from '../Banner/Slider'
import IconComponenet from '../HomePage/IconComponenet'
import VideoComponent from '../VideoComponent/VideoComponent'

const AboutUs = () => {
    const iframeRef = useRef(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <div>
        <Grid container spacing={0} className={style.aboutmaincon}>
            <Grid item xs={12} md={12}>
                <Appbar />
            </Grid>
            <Grid item xs={12} md={12}>
                <Slider />
            </Grid>
            <Grid item xs={12} md={10}>
                <Grid container spacing={2} className={style.aboutsubcon}>
                    <Grid item xs={12} md={12}>
                <div className={style.heading}>
                    About Us
                </div>
                    </Grid>
            <Grid item xs={12} md={9} className={style.infocontainer}>
            <div className={style.subhead}>
            Home Of Premium Indian Mangoes
            </div>
            <div className={style.aboutinfo}>
            Mavlamangoes stands as your premier destination, offering an exquisite array of premium Indian mango varieties tailored exclusively for discerning tastes across America. Our ethos revolves around delivering an unparalleled mango experience, striving to curate selections that embody the essence of authenticity and natural goodness.
            </div>
            <div className={style.aboutinfo}>
            Established in the year 2017-18, Mavlamangoes has been steadfast in its commitment to sourcing and delivering the finest Indian mangoes to American shores. Led by Mr. Mavlamangoes, a passionate connoisseur and co-founder of Mavlamangoes, our journey began with a vision to share the rich flavors and cultural heritage encapsulated within each succulent mango. Mr. Mavlamangoes unwavering dedication stems from a profound love for Indian mangoes, igniting the spark that fueled the inception of Mavlamangoes.
            </div>
            <div className={style.aboutinfo}>
            Our endeavor extends beyond mere commerce; it is a testament to the relentless pursuit of excellence. Throughout the year, our dedicated team collaborates closely with esteemed farmers and suppliers across India's mango-growing regions. Through meticulous selection and rigorous quality control measures, we ensure that only the finest mangoes, brimming with natural sweetness and exceptional flavor, find their way to our valued customers in America.
            </div>
            <div className={style.aboutinfo}>
            At Mavlamangoes, we take pride in fostering a marketplace where authenticity meets affordability. We believe that everyone deserves access to the finest Indian mangoes, and thus, we strive to maintain reasonable prices without compromising on quality. Whether you live in bustling urban centers or tranquil locales, Mavlamangoes strives to deliver the essence of India's mango culture to your doorstep
            </div>
            <div className={style.aboutinfo}>
            Join us in celebrating the mango season with Mavlamangoes, where each bite promises to transport you to the sun-kissed orchards of India, where the fragrance of ripe mangoes lingers in the air, and the sweetness of each fruit embodies a timeless tradition of indulgence. Experience the magic of Mavlamangoes – your gateway to premium Indian mangoes in America.
            </div>
            </Grid>
            <Grid item xs={12} md={3}>
            <div className={style.videosub}>
              <iframe
                  width="100%"
                  className={style.videosub}
                  height={isMobile ? "200" : "500"}
                  ref={iframeRef}
                  src="https://www.youtube.com/embed/HtEyHZpEN1o?si=iDUTVWLc2m8r3Wqp"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  referrerPolicy="no-referrer"
                />
              </div>
            </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <Footer />
            </Grid>
            <Grid item xs={12} md={12} mt={2}>
                <IconComponenet />
            </Grid>
        </Grid>
    </div>
  )
}

export default AboutUs