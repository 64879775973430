import { configureStore } from '@reduxjs/toolkit';
import confirmDialogReducer from '../Reducer/confirmDialogReducer';
import alertReducer from '../Reducer/alertReducer';
import ContactReducer from '../Reducer/ContactReducer';

export default configureStore({
  reducer: {
    confirm:confirmDialogReducer,
    alert:alertReducer,
    contact:ContactReducer,
  },
});