import { Grid } from '@mui/material'
import React from 'react'
import style from "./Footer.module.css"

const Footer = () => {
  return (
    <div className={style.footermain}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <div className={style.footercon}>Copyright © 2024 Mavlamangoes</div>
            </Grid>
        </Grid>
    </div>
  )
}

export default Footer