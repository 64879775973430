import {
  Button,
  Grid,
  Paper,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect } from "react";
import Appbar from "../Appbar/Appbar";
import Footer from "../Footer/Footer";
import style from "./ContactUs.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailContactSend,
  SaveContact,
  actionConfirm,
  handleContactChange,
} from "../../API/Reducer/ContactReducer";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";
import AlertComp from "../AlertMessageCom/AlertComp";
import {
  handleAlphanumericInputwithSpaceChange,
  handleNumInputChange,
  isSmallScreen,
} from "../../MIS/Global";
import IconComponenet from "../HomePage/IconComponenet";
import EmailPage from "../EmailPage/EmailPage";
import ReactDOMServer from "react-dom/server";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactUs = () => {
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.contact);
  const { resans, type } = useSelector((state) => state.confirm);

  const handleCitySuggestChange = (e) => {
    dispatch(handleContactChange(e?.label, "city"));
  };

  useEffect(() => {
    if (resans === true && type == "save") {
      dispatch(SaveContact(contact));
    } else if (resans === true && type == "del") {
    }
  }, [resans]);

  return (
    <div>
      <ReduxConfirmationDialog />
      <AlertComp />
      <Grid container spacing={0} className={style.contactmaincon}>
        <Grid item xs={12} md={12}>
          <Appbar />
        </Grid>

        <Grid item xs={11} md={7} className={style.contactpapercontainer}>
          <Paper elevation={2} className={style.contactpaper}>
            <Grid container spacing={2} className={style.contactsub}>
              <Grid item xs={12} md={12}>
                <div className={style.heading}>Contact Us</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-disabled"
                  label="First Name"
                  fullWidth
                  size="small"
                  name="fname"
                  value={contact?.fname || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                    inputProps: {
                      onInput: handleAlphanumericInputwithSpaceChange,
                      maxLength: 100,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-disabled"
                  label="Last Name"
                  fullWidth
                  size="small"
                  name="lname"
                  value={contact?.lname || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                    inputProps: {
                      onInput: handleAlphanumericInputwithSpaceChange,
                      maxLength: 100,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-disabled"
                  label="Email"
                  fullWidth
                  size="small"
                  name="uemail"
                  value={contact?.uemail || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                    inputProps: {
                      maxLength: 250,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-disabled"
                  label="Contact No"
                  fullWidth
                  size="small"
                  name="ucont"
                  value={contact?.ucont || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                    inputProps: {
                      onInput: handleNumInputChange,
                      maxLength: 10,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <CityTextSuggest 
               value={contact?.city || ""}
               handleChange={handleCitySuggestChange}
              /> */}
                <TextField
                  id="outlined-disabled"
                  label="City"
                  fullWidth
                  size="small"
                  name="city"
                  value={contact?.city || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                    inputProps: {
                      onInput: handleAlphanumericInputwithSpaceChange,
                      maxLength: 100,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-disabled"
                  label="Subject"
                  fullWidth
                  size="small"
                  name="subject"
                  value={contact?.subject || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                    inputProps: {
                      maxLength: 250,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  maxRows={5}
                  placeholder="Message"
                  name="msg"
                  value={contact?.msg || ""}
                  onChange={(e) => {
                    dispatch(handleContactChange(e, ""));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    fontSize: "16px",
                    padding: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2.5}>          
                <Button
                  className={style.sendbtnconn}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    // dispatch(actionConfirm("save"));
                    const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                      <EmailPage data={contact} />
                    );
                    let data={
                      htmlmsg:`${htmlEmail.toString()}`,
                      emailid:"",
                    }
                    dispatch(EmailContactSend(data));
                  }}
                >
                  Send Message
                </Button>
              </Grid>
              <Grid item xs={12} md={9} sx={{display:"flex",alignItems:isSmallScreen()===true?"flex-start":"center",justifyContent:"end"}}>
                <LocationOnIcon style={{color:"#EA4335"}}/>
                <span style={{fontWeight:"bold", fontStyle:"italic"}}>Mavla Import LLC 27311 Waterford Glen Katy, Tx 77494.</span>
              </Grid>  
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} className={style.footersty}>
          <Footer />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <IconComponenet />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
