import { Grid } from '@mui/material'
import React from 'react'
import style from "./Home.module.css"
import img1 from "../../Assets/sakura.png"
import img2 from "../../Assets/1234.png"
import img3 from "../../Assets/shopping-cart.png"
import img4 from "../../Assets/bg3-h4-1.png"
import img5 from "../../Assets/fast-delivery.png"

const HomePageDesign = () => {
  return (
    <div>
        <Grid container spacing={2} className={style.homemaincon}>
            <Grid item xs={4} md={2} className={style.cardmobile}>
                <div className={style.cardimgcon}>
                    <img src={img1} className={style.cardimg} />
                </div>
                <div className={style.iconname}>
                    Pick Farm Fresh Organic Mangoes
                </div>
            </Grid>
            <Grid item xs={4} md={2} className={style.cardmobile}>
                <div className={style.cardimgcon}>
                    <img src={img2} className={style.cardimg} />
                </div>
            </Grid>
            <Grid item xs={4} md={2} className={style.cardmobile}>
                <div className={style.cardimgcon} >
                    <img src={img3} className={style.cardimg} />
                </div>
                <div className={style.iconname}>
                Order Mango Delights
                </div>
            </Grid>
            <Grid item xs={4} md={2} className={style.cardmobile}>
                <div className={style.cardimgcon}>
                    <img src={img4} className={style.cardimg} />
                </div>
            </Grid>
            <Grid item xs={4} md={2} className={style.cardmobile}>
                <div className={style.cardimgcon}>
                    <img src={img5} className={style.cardimg} />
                </div>
                <div className={style.iconname}>
                Door Step Delivery
                </div>
            </Grid>
        </Grid>
    </div>
  )
}

export default HomePageDesign