import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
 

const alertReducer = createSlice({
    name: 'alert',
    initialState: {
        alertData: [],            
    },

    reducers: {
        setAlert(state, action){
            state.alertData=action.payload;         
         },
         setAlertClose(state, action){
            state.alertData = {
                ...state.alertData,
                open: action.payload?.open,
              };
         },
             
    }
});

export const { setAlert,setAlertClose} = alertReducer.actions;
export default alertReducer.reducer;

export const setAlertData = (data) => {
    let intialData={
        type: data?.type || "error",
        msg: data.msg || "Welecome",
        vertical: data?.vertical || "top",
        horizontal: data?.horizontal || "center",
        open: data?.horizontal || true,
        code: data?.code || 100,
        duration: data?.duration || 3000
    }

    return  function actionData(dispatch){
        dispatch(setAlert(intialData));
    }
}
export const alertClose = (data) => { 
    return  function actionData(dispatch){
        dispatch(setAlertClose({open:false}));
    }
}
 