import React from "react";

const EmailPage = (props) => {
  const bgcolor = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "300px",
    height: "100%",
  };
  const bgImg = {
    width: "100%",
    height: window.innerWidth <= 768 ? "20vh" : "25vh",
  };

  const box = {
    boxShadow: "1px 1px 1px 1px rgba(12,12,12,0.2)",
    borderRadius: "5px",
    marginTop: "10px",
    // padding: "20px",
    width: window.innerWidth <= 768 ? "90%" : "30%",
    display: "flex",
    flexDirection: "column",
  };
  const subboxcon = {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };
  const titlecolor = {
    color: "black",
    fontSize: 16,
    with: "100%",
  };

  const heading = {
    color: "black",
    fontSize: 20,
    fontWeight: 600,
    with: "100%",
  };
  const headingtxt = {
    color: "black",
    fontSize: 24,
    fontWeight: 600,
    with: "100%",
  };
  const textcontainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "5px",
    textAlign: "center",
  };
  const text = {
    color: "black",
    fontSize: 16,
    fontWeight: 600,
    with: "100%",
  };
  return (
    <>
      <img
        style={{ width: "100%", objectFit: "contain", height: "150px" }}
        src="https://cms.araiindia.com/MediaFiles/contactusbanner_1073.png"
      />

      <p style={{ width: "100%" }}>Hi!</p>
      <p style={{ width: "100%",fontWeight:"bold" }}>        
        {props?.data?.fname}&nbsp;{props?.data?.lname}      
      </p>
      <p style={{fontWeight:"bold" }}>{props?.data?.ucont}</p> 
      <p>
        {props?.data?.uemail}
      </p>
      <p>        
        {props?.data?.city}
      </p>
      <p style={{ width: "100%",fontWeight:"bold" }}>
        Subject:{props?.data?.subject}
      </p>
      <p style={{ width: "100%",fontWeight:"bold" }}>
        {props?.data?.msg}
      </p>
     
    </>
  );
};

export default EmailPage;
