import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Box,
    Grid,
    Modal,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";
import style from "../Component/AdminEnquiry/Enquiry.module.css"
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../API/Reducer/ContactReducer";




const arr = [1, 2, 3, 4, 5, 6,7,8,9,10];
const EnquiryTable = (props) => {

  const dispatch = useDispatch();
  const { contactList } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContact());
  }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [openmodal, setOpenmodal] = useState(false);

    const rowsPerPageOptions = [
        25,50,100,200,300,500,1000,
        { value: contactList?.length, label: "All" },
      ];
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
      };

      const handlemodalOpen = () => {
      setOpenmodal(true);
    };
    
    const handlemodalClose = () => {
      setOpenmodal(false);
    };

  return (
    <>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "70vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={style.tblHead}>Name</TableCell>
                  <TableCell className={style.tblHead}>Contact</TableCell>
                  <TableCell className={style.tblHead}>City</TableCell>
                  <TableCell className={style.tblHead}>Title</TableCell>
                  <TableCell className={style.tblHead1}>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {contactList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((element, index) => {               
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>
                       {/* Tanaji Patil */}
                       {element?.uname}
                      </TableCell>
                      <TableCell>
                       {/* 9963250014 */}
                       {element?.ucont}
                      </TableCell>
                      <TableCell>{element?.city}</TableCell>
                      <TableCell>{element?.subject}</TableCell>
                      <TableCell
                      onClick={()=>{
                          handlemodalOpen();
                      }}
                      >
                        <div className={style.tablediscription}>
                        {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quas eius possimus, nemo molestias consequatur, architecto labore cum magni maxime perferendis sapiente necessitatibus. Quaerat excepturi corporis dolores, voluptate minima ab.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quas eius possimus, nemo molestias consequatur, architecto labore cum magni maxime perferendis sapiente necessitatibus. Quaerat excepturi corporis dolores, voluptate minima ab? */}
                        {element?.msg}
                            </div></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={contactList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
       </div>
       <Modal
        open={openmodal}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // heading={fundrequest?.modalHeading}
      >
        <Box className={style.modaladd}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            // component="h2"
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handlemodalClose();
                }}
              />
            </div>
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={7} className={style.modalcontaint}>
            <div className={style.nametxt}>User Name:</div>
            <div className={style.desctxt}> Tanaji Patil</div>
            </Grid>
            <Grid item xs={12} md={5} className={style.modalcontaint}>
            <div className={style.nametxt}>Contact Number:</div>
            <div className={style.desctxt}>9963250014</div>
            </Grid>
            <Grid item xs={12} md={12} className={style.modalcontaint}>
            <div className={style.nametxt}>Discription: </div>
            <div className={style.desctxt}>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quas eius possimus, nemo molestias consequatur, architecto labore cum magni maxime perferendis sapiente necessitatibus. Quaerat excepturi corporis dolores, voluptate minima ab.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quas eius possimus, nemo molestias consequatur, architecto labore cum magni maxime perferendis sapiente necessitatibus. Quaerat excepturi corporis dolores, voluptate minima ab?
            </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default EnquiryTable;
