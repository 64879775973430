import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import style from "./Order.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MobileAdminOrder = () => {
  const [value, setValue] = React.useState(0);
  const item = [1, 2, 3, 4];

  const [expanded, setExpanded] = React.useState(false);
  const [showallcontent, setShowallcontent] = useState(false);
  const [completecontent, setCompletecontent] = useState(false);

  const toggleContent = () => {
    setShowallcontent(!showallcontent);
  };
  const CompletetoggleContent = () => {
    setCompletecontent(!completecontent);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid container spacing={2} className={style.listmain}>
        <Grid item xs={12} md={12}>
          <div className={style.listheading}>Order List</div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Complete" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  {item?.map((element, index) => {
                    let bgcolor = "#F1F0E8";
                    if (index % 2 == 0) {
                      bgcolor = "#fff";
                    }
                    return (
                      <Grid item xs={12} md={12}>
                        <Accordion
                          component={Paper}
                          expanded={expanded === index}
                          onChange={handleAccordionChange(index)}
                        >
                          <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{ backgroundColor: bgcolor }}
                          >
                            <Typography style={{ width: "70%" }}>
                              <div className={style.transid}>
                                pratiksaha patil
                              </div>
                              <div className={style.historydetime}>
                                delivery :04/04/2024
                              </div>
                            </Typography>
                            <Typography
                              style={{
                                width: "30%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <div className={style.transid}>9685201473</div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>Order:</div>
                                  <div className={style.titletxt}>
                                    04/04/2024
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>
                                    Quality:
                                  </div>
                                  <div className={style.titletxt}>Good</div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>
                                    Mangotype:
                                  </div>
                                  <div className={style.titletxt}>Hapus</div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>
                                    Address:
                                  </div>
                                  <div className={style.titletxt}>
                                    <div
                                      className={`${style.tablediscription} ${
                                        showallcontent ? style.showFull : ""
                                      }`}
                                    >
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Ut ea reiciendis
                                      doloremque mollitia illum, eos autem
                                      adipisci delectus harum consequatur, sequi
                                      itaque rem quo natus excepturi ipsam
                                      obcaecati consectetur! Accusamus! Lorem
                                      ipsum dolor sit amet consectetur
                                      adipisicing elit. Ut ea reiciendis
                                      doloremque mollitia illum, eos autem
                                      adipisci delectus harum consequatur, sequi
                                      itaque rem quo natus excepturi ipsam
                                      obcaecati consectetur! Accusamus! Lorem
                                      ipsum dolor sit amet consectetur
                                      adipisicing elit. Ut ea reiciendis
                                      doloremque mollitia illum, eos autem
                                      adipisci delectus harum consequatur, sequi
                                      itaque rem quo natus excepturi ipsam
                                      obcaecati consectetur! Accusamus!
                                    </div>
                                    {!showallcontent && (
                                      <a
                                        href="#"
                                        className={style.readmore}
                                        onClick={toggleContent}
                                      >
                                        Read More
                                      </a>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  {item?.map((element, index) => {
                    let bgcolor = "#F1F0E8";
                    if (index % 2 == 0) {
                      bgcolor = "#fff";
                    }
                    return (
                      <Grid item xs={12} md={12}>
                        <Accordion
                          component={Paper}
                          expanded={expanded === index}
                          onChange={handleAccordionChange(index)}
                        >
                          <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{ backgroundColor: bgcolor }}
                          >
                            <Typography style={{ width: "70%" }}>
                              <div className={style.titletxt}>
                                pratiksaha patil
                              </div>
                              <div className={style.historydetime}>
                                delivery :04/04/2024
                              </div>
                            </Typography>
                            <Typography
                              style={{
                                width: "30%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <div className={style.titletxt}>9685201473</div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>Order:</div>
                                  <div className={style.titletxt}>
                                    04/04/2024
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>
                                    Quality:
                                  </div>
                                  <div className={style.titletxt}>Good</div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>
                                    Mangotype:
                                  </div>
                                  <div className={style.titletxt}>Hapus</div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={style.txtcontainer}
                                >
                                  <div className={style.titlehead}>
                                    Address:
                                  </div>
                                  <div className={style.titletxt}>
                                    <div
                                      className={`${style.tablediscription} ${
                                        completecontent ? style.showFull : ""
                                      }`}
                                    >
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Ut ea reiciendis
                                      doloremque mollitia illum, eos autem
                                      adipisci delectus harum consequatur, sequi
                                      itaque rem quo natus excepturi ipsam
                                      obcaecati consectetur! Accusamus! Lorem
                                      ipsum dolor sit amet consectetur
                                      adipisicing elit. Ut ea reiciendis
                                      doloremque mollitia illum, eos autem
                                      adipisci delectus harum consequatur, sequi
                                      itaque rem quo natus excepturi ipsam
                                      obcaecati consectetur! Accusamus! Lorem
                                      ipsum dolor sit amet consectetur
                                      adipisicing elit. Ut ea reiciendis
                                      doloremque mollitia illum, eos autem
                                      adipisci delectus harum consequatur, sequi
                                      itaque rem quo natus excepturi ipsam
                                      obcaecati consectetur! Accusamus!
                                    </div>
                                    {!completecontent && (
                                      <a
                                        href="#"
                                        className={style.readmore}
                                        onClick={CompletetoggleContent}
                                      >
                                        Read More
                                      </a>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              </CustomTabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileAdminOrder;
