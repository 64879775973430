import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
    Grid,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";
import style from "../Component/OrderBook/OrderBook.module.css"
import img1 from "../Assets/product4.png"
import img2 from "../Assets/product5.png"
import img3 from "../Assets/product3.jpg"
import img4 from "../Assets/product6.png"
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles({
    noBorder: {
      border: "none !important",
      "& .MuiInput-underline:before": {
        // borderBottom: `${props.border=="none"?"none !important":""}`,
        borderBottom: "none !important",
      },
    },
  });

const Products = [
    {
        pname:"Hapus Mangoes",
        imgpath: img2,
        price:"59.00",
        qty:"2",
        unit:"box",
        totprice:"118.00"
    },
    {
        pname:"Indian Alphonso Mangoes",
        imgpath: img3,
        price:"60.00",
        qty:"2",
        unit:"box",
        totprice:"120.00"
    },
   
    {
        pname:"Banginapalli Mangoes",
        imgpath: img1,
        price:"69.00",
        qty:"1",
        unit:"box",
        totprice:"69.00"
    },
    {
        pname:"Kesar Mangoes",
        imgpath: img4,
        price:"50.00",
        qty:"2",
        unit:"box",
        totprice:"100.00"
    },
  ];

const arr = [1, 2, 3, 4, 5, 6,7,8,9,10];
const ProductTable = (props) => {
    const classes = useStyles(); 

  return (
    <>
      <div>
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={style.tblHead}></TableCell>
                  <TableCell className={style.tblHead}>Name</TableCell>
                  <TableCell className={style.tblHead}>Price</TableCell>
                  <TableCell className={style.tblHead}>Quantity</TableCell>
                  <TableCell className={style.tblHead}>Unit</TableCell>
                  <TableCell className={style.tblHead}>Total Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {Products?.map((element, index) => {               
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>
                        <div className={style.tblimgcon}>
                            <img src={element?.imgpath} className={style.tblimg} />
                        </div>
                      </TableCell>
                      <TableCell>
                      {element?.pname}
                      </TableCell>
                      <TableCell >{element?.price}</TableCell>
                      <TableCell>
                      <TextField
                                size="small"
                                variant="standard"
                                // label="Number"
                                className={classes.noBorder}
                                value={element?.qty}
                                // onChange={(e)=>{props?.data?.handleUpdatePlay(element?.id,"npoints",e.target.value,element?.num2,e.target.value)}}
                                sx={{
                                  marginBottom: "5px",
                                  textAlign:"center",
                                  width:"50%"
                                }}
                                InputProps={{
                                  classes: {
                                    input: style.textinp_left,
                                  },
                                }}
                                InputLabelProps={{
                                  className: style.textinplbl,
                                  shrink: true,
                                }}
                              />
                      </TableCell>
                      <TableCell>
                      {element?.unit}
                      </TableCell>
                      <TableCell style={{textAlign:"end"}}>
                      {element?.totprice}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Grid container spacing={2} sx={{display:"flex",justifyContent:"end"}}>
                <Grid item xs={12} md={4}>
            <div className={style.totalcontainer}>
                <div className={style.totalhead}>Total:</div>
                <div className={style.totalhead}>407.00</div>
            </div>
                </Grid>
            </Grid>
          </TableContainer>
       </div>
    </>
  );
};
export default ProductTable;
