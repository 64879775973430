import { Grid } from '@mui/material'
import React from 'react'
import img from "../../Assets/maintenance.jpg"
import style from "./Maintenance.module.css"

const MaintenancePage = () => {
  return (
    <div>
        <Grid container className={style.maincontainer}> 
            <Grid item xs={12} md={5}>
                <Grid container spacing={2} className={style.subcontainer}>
                    <Grid item xs={12} md={12}>
                        <div className={style.oppstxt}>Opps!</div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className={style.maintext}>Website under Maintenance</div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className={style.subtext}>This website is undergoing maintenance. We should be back shorty.</div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
                <div className={style.imgcontainer}>
                    <img className={style.mainimg} src={img} />
                </div>
            </Grid>
        </Grid>
    </div>
  )
}

export default MaintenancePage