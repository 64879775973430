import React, { createContext, useEffect, useState,useReducer, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const navigate = useNavigate();
  
  useEffect(() => {  
    const fetchData = async () => {
try{    


    let localId=localStorage.getItem("catgid");

    // alert(JSON.stringify(localStorage.getItem("loginId")))
    let currentPath=window.location.pathname;
    // alert(currentPath)
    // alert(loginDetailsState);
    
  }
  catch(e){}
  };
  fetchData();
    },[])
  const value = {
    
   
   
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};



export default UserContextProvider;
