import { Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import style from "./Video.module.css";

const VideoComponent = () => {
  const iframeRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const youtubeLink = "https://www.youtube.com/embed/shorts/HtEyHZpEN1o?si=iDUTVWLc2m8r3Wqp";




  return (
    <div>
      <Grid container spacing={2} className={style.videomaincon}>
        <Grid item xs={12} md={12}>
          {/* <div className={style.prodheading}>HOME OF PREMIUM INDIAN MANGOES</div> */}
        </Grid>           
         <Grid item xs={12} md={12}>
              <div className={style.videosub}>
              <iframe
                  width="100%"
                  className={style.videosub}
                  height={isMobile ? "200" : "500"}
                  ref={iframeRef}
                  src="https://www.youtube.com/embed/HtEyHZpEN1o?si=iDUTVWLc2m8r3Wqp"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  referrerPolicy="no-referrer"
                />
              </div>
            </Grid>
      </Grid>
    </div>
  );
};

export default VideoComponent;
