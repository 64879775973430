import { Grid, Paper } from '@mui/material'
import React from 'react'
import style from "./Productcard.module.css"
import img1 from "../../Assets/product4.png"
import img2 from "../../Assets/product5.png"
import img3 from "../../Assets/product3.jpg"
import img4 from "../../Assets/product6.png"
import { useNavigate } from 'react-router-dom'

const Productcard = [
    {
        pname:"Hapus Mangoes",
        imgpath: img2,
        price:"59.00",
    },
    {
        pname:"Indian Alphonso Mangoes",
        imgpath: img3,
        price:"60.00",
    },
   
    {
        pname:"Banginapalli Mangoes",
        imgpath: img1,
        price:"69.00",
    },
    {
        pname:"Kesar Mangoes",
        imgpath: img4,
        price:"50.00",
    },
  ];

const ProductCard = () => {
    const navigate = useNavigate();
  return (
    <div>
        <Grid container spacing={2} className={style.productcardcon}>
            <Grid item xs={12} md={12}>
                <div className={style.prodheading}>HOME OF PREMIUM INDIAN MANGOES</div>
            </Grid>
            <Grid item xs={12} md={10}>
                <div className={style.proddesc}>Mavlamangoes is your destination for acquiring premium Indian mangoes in America. Our mission is to offer mangoes of the highest quality from India, ensuring they are as natural as possible.</div>
            </Grid>
        {Productcard?.map((element, index) => {
            return(
            <Grid item xs={6} md={2.5} className={style.cardmaincon}
            // onClick={()=>{
            //     navigate("/productdetail")
            // }}
            >
                <Paper elevation={2}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <div className={style.prodimgcon}>
                            <img src={element?.imgpath} className={style.prodimg} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} className={style.subcontainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className={style.prodnamesty}>
                                    {element?.pname}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className={style.prodprice}>
                                        ${element?.price}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            )
        })}
        </Grid>
    </div>
  )
}

export default ProductCard