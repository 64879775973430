import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import style from "./Banner.module.css";
import img1 from "../../Assets/banner1.png";
import img2 from "../../Assets/banner2.png";
import img3 from "../../Assets/banner3.png";
import img4 from "../../Assets/banner4.png";
import img5 from "../../Assets/banner5.jpg";
import img6 from "../../Assets/banner_devgad_hapus.jpg";
import img7 from "../../Assets/banner_ratnagiri_hapus.jpg";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const images = [
  // {
  //     imgpath: img5,
  // },
  {
    imgpath: img1,
  },
  {
    imgpath: img2,
  },
  {
    imgpath: img3,
  },
  {
    imgpath: img4,
  },
  {
    imgpath: img6,
  },
  {
    imgpath: img7,
  },
];

const Banner = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % images.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? images.length - 1 : prevActiveStep - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevActiveStep) => (prevActiveStep + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <div className={style.slidercontainer}>
            {images?.map((element, index) => (
              <div
                key={index}
                style={{ display: index === activeStep ? "block" : "none" }}
                className={style.sliderimgcon}
              >
                <img
                  className={style.slideimg}
                  src={element?.imgpath}
                  alt={`Image ${index}`}
                />
              </div>
            ))}
            <div
              className={`${style.arrowButton} ${style.leftArrow}`}
              onClick={handleBack}
              aria-label="previous"
            >
              <KeyboardArrowLeft />
            </div>
            <div
              className={`${style.arrowButton} ${style.rightArrow}`}
              onClick={handleNext}
              aria-label="next"
            >
              <KeyboardArrowRight />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Banner;
