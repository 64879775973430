import { Button, Grid, Paper, TextField } from "@mui/material";
import React from "react";
import Appbar from "../Appbar/Appbar";
import img1 from "../../Assets/orderheading.png";
import img2 from "../../Assets/mangohead.png";
import img3 from "../../Assets/product1.jpg";
import style from "./OrderBook.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { makeStyles } from "@material-ui/core";
import ProductTable from "../../Table/ProductTable";
import Footer from "../Footer/Footer";
import IconComponent from "../HomePage/IconComponenet";
import img4 from "../../Assets/product4.png";
import img5 from "../../Assets/product5.png";
import img6 from "../../Assets/product3.jpg";
import img7 from "../../Assets/product6.png";

const Products = [
  {
    pname: "Hapus Mangoes",
    imgpath: img5,
    price: "59.00",
    qty: "2",
    unit: "box",
    totprice: "118.00",
  },
  {
    pname: "Indian Alphonso Mangoes",
    imgpath: img6,
    price: "60.00",
    qty: "2",
    unit: "box",
    totprice: "120.00",
  },

  {
    pname: "Banginapalli Mangoes",
    imgpath: img4,
    price: "69.00",
    qty: "1",
    unit: "box",
    totprice: "69.00",
  },
  {
    pname: "Kesar Mangoes",
    imgpath: img7,
    price: "50.00",
    qty: "2",
    unit: "box",
    totprice: "100.00",
  },
];

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "16px",
      fontWeight: "500 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "500 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
  noBorder: {
    border: "none !important",
    "& .MuiInput-underline:before": {
      // borderBottom: `${props.border=="none"?"none !important":""}`,
      borderBottom: "none !important",
    },
  },
}));

const OrderBook = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={0} className={style.maincontainer}>
        <Grid item xs={12} md={12}>
          <Appbar />
        </Grid>
        <Grid item xs={12} md={7} className={style.subcontainer}>
          <Grid container spacing={2}>
            <Grid item xs={10} md={11}>
              <div className={style.heading}>Order Book</div>
            </Grid>
            <Grid item xs={2} md={1} className={style.savebtnposition}>
              <Button
                className={style.sendbtnconn}
                variant="contained"
                size="small"
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                label="Full Name"
                fullWidth
                size="small"
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                  //   inputProps: {
                  //     onInput:
                  //       handleAlphanumericInputwithSpaceChange,
                  //     maxLength: 100,
                  //   },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                label="Contact Number"
                fullWidth
                size="small"
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                  //   inputProps: {
                  //     onInput:
                  //       handleAlphanumericInputwithSpaceChange,
                  //     maxLength: 100,
                  //   },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                label="Address"
                multiline
                maxRows={3}
                fullWidth
                size="small"
                InputProps={
                  {
                    //   inputProps: {
                    //     onInput:
                    //       handleAlphanumericInputwithSpaceChange,
                    //     maxLength: 100,
                    //   },
                  }
                }
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="Delivery Date"
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" />
                  )}
                  openTo="day"
                  value={dayjs(new Date())}
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12} className={style.ordertbldis}>
              <Paper elevation={2} className={style.tablepaper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} >
                    <div className={style.prodheading}>Product</div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ProductTable />
                  </Grid>
                
                </Grid>
              </Paper>
            </Grid>
            {Products?.map((element, index) => { 
              return(
            <Grid item xs={12} className={style.mobiledisplay}>
                  <Paper elevation={2} className={style.tablepaper}>
                    <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <div className={style.tblimgcon}>
                      <img src={element?.imgpath} className={style.tblimg} />
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div className={style.productname}>
                    {element?.pname}
                    </div>
                  </Grid>
                  <Grid item xs={3} className={style.savebtnposition}>
                    <div className={style.productname}>${element?.price}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      // label="Number"
                      className={classes.noBorder}
                      value={element?.qty}
                      // onChange={(e)=>{props?.data?.handleUpdatePlay(element?.id,"npoints",e.target.value,element?.num2,e.target.value)}}
                      sx={{
                        marginBottom: "5px",
                        textAlign: "center",
                      }}
                      InputProps={{
                        classes: {
                          input: style.textinp_left,
                        },
                      }}
                      InputLabelProps={{
                        className: style.textinplbl,
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div className={style.qtysty}>{element?.unit}</div>
                  </Grid>
                  <Grid item xs={5} className={style.savebtnposition}>
                    <div className={style.productname}>${element?.totprice}</div>
                  </Grid>
                  
                  <Grid item xs={12} className={style.savebtnposition}>
                    <div className={style.totalpricesty}>
                      <div className={style.productname}>Total:</div>
                    <div className={style.productname}>$407.00</div>
                    </div>
                  </Grid>
                    </Grid>
                    </Paper>
                  </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} className={style.orderimgmaincon}>
          <div className={style.orderimgcon}>
            <img src={img1} className={style.orederimg} />
          </div>
          {/* <div className={style.orderimglogocon}>
            <img src={img2} className={style.orederimg} />
          </div> */}
          <div className={style.ordermangoimgcon}>
            <img src={img3} className={style.ordermangoimg} />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
        <Grid item xs={12} md={12}>
          <IconComponent />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderBook;
