import { Grid } from '@mui/material'
import React from 'react'
import style from "./Enquiry.module.css"
import EnquiryTable from '../../Table/EnquiryTable'

const AdminEnquiry = () => {
 
  return (
    <div>
        <Grid container spacing={2} className={style.enquirymain}>
            <Grid item xs={12} md={12}>
                <div className={style.enquiryheading}>Enquiry List</div>
            </Grid>
            <Grid item xs={12} md={12}>
                <EnquiryTable />
            </Grid>
        </Grid>
    </div>
  )
}

export default AdminEnquiry