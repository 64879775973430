import { Grid } from '@mui/material'
import React from 'react'
import style from "./Banner.module.css"
import img1 from "../../Assets/banner7.jpg"

const Slider = () => {
  return (
    <div>
    <Grid container>
        <Grid item xs={12} md={12}>
        <div className={style.sliderimgcon}>
                <img 
                className={style.slideimg}
                src={img1}
                />
                </div>
        </Grid>
    </Grid>
    </div>
  )
}

export default Slider