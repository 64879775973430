import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import img from "../../Assets/mango.png"
import Slide from "@mui/material/Slide";
import style from "./Appbar.module.css"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchIcon from '@mui/icons-material/Search'
import HomeIcon from '@mui/icons-material/Home';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';
import ListIcon from '@mui/icons-material/List';


const drawerWidth = 240;
const navItems = ["Home","Mango Varieties","About Us","Contact Us"];
const navLinks = [
  "/",
  "/verieties",
  "/aboutus",
  "/contactus",
];

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Appbar = (props) => {
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);



  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        // backgroundColor: "#E5EAE2",
        color: "black",
        height: "100vh",
      }}
    >
      <Typography
        variant="h6"
        sx={{ my: 2, display: "flex",justifyContent:"center" }}
        onClick={() => {
          navigate("/home");
        }}
      >       
               <div className={style.logocontainer}>
                <div className={style.logocon}>
                  <img src={img} className={style.logosty}/>
                </div>
                  <div className={style.namecon}><span className={style.nametxt1}>Mavla</span><span className={style.nametxt2}>Mangoes</span></div>
                </div>
      </Typography>
      <Divider />
      <List>
        {/* {navItems.map((item, index) => {
          let menuname = item;
          let dis = "";

          return ( */}
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center"}}
               onClick={()=>{
                navigate("/");
              }}
              >
                <ListItemText >
                  <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                  <HomeIcon />
                    Home
                    </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center"}}
               onClick={()=>{
                navigate("/verieties");
              }}
              >
                <ListItemText >
                  <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                  <ListIcon />
                  Mango Varieties
                    </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center"}}
               onClick={()=>{
                navigate("/aboutus");
              }}
              >
                <ListItemText >
                  <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                  <InfoIcon />
                  About Us
                    </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center"}}
               onClick={()=>{
                navigate("/contactus");
              }}
              >
                <ListItemText >
                  <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                  <ContactPageIcon />
                  Contact Us
                    </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          {/* );
        })} */}
      </List>
    </Box>
  );

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        {/* <HideOnScroll {...props}> */}
          <AppBar className={`${style.appbar} ${scrolled ? `${style.scrolled}` : ''}`}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, color: "black", display: { sm: "none" } }}
              >
                <MenuIcon />
                <div className={style.logocontainer}>
                <div className={style.logocon}>
                  <img src={img} className={style.logosty}/>
                </div>
                  <div className={style.namecon}><span className={style.nametxt1}>Mavla</span><span className={style.nametxt2}>Mangoes</span></div>
                </div>
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block", color: "black" } }}
              >
                <div className={style.logocontainer}>
                <div className={style.logocon}>
                  <img src={img} className={style.logosty}/>
                </div>
                  <div className={style.namecon}><span className={style.nametxt1}>Mavla</span><span className={style.nametxt2}>Mangoes</span></div>
                </div>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "space-between",
                  alignItems:"center",
                }}
              >
                {/* <Typography sx={{display:"flex",alignItems:"center",width:"400px"}}>
                <div className={style.searchcon}>
                <SearchIcon sx={{ fontSize: '22px', cursor: 'pointer',color:"#547290" }} />
                  <input
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                    // onChange={(e) => {
                    //   setpsearch(e.target.value)
                    // }}
                  />
                  
                </div>
                </Typography> */}
                {navItems.map((item, index) => {
                  let menuname = item;
                  let dis = "";
                  return (
                    <Button key={item} sx={{ color: "black", display: dis }}
                    onClick={()=>{
                      navigate(`${navLinks[index]}`);
                    }}
                    >
                      {menuname}
                    </Button>
                  );
                })}
              </Box>
            </Toolbar>
          </AppBar>
        {/* </HideOnScroll> */}
        <Toolbar />
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </React.Fragment>
    </div>
  );
};

export default Appbar;
