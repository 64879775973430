import { Grid, Paper } from '@mui/material'
import React from 'react'
import style from "./Enquiry.module.css"
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";


const MobileAdminEnquiry = () => {
    const item =[1,2,3,4]

    const [expanded, setExpanded] = React.useState(false);
      
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
        <Grid container spacing={2} className={style.enquirymain}>
            <Grid item xs={12} md={12}>
                <div className={style.enquiryheading}>Enquiry List</div>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                {item?.map((element, index) => {
            let bgcolor = "#F1F0E8";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            return (
              <Grid item xs={12} md={12}>
                <Accordion component={Paper} expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ backgroundColor: bgcolor }}
                  >
                    <Typography style={{width:"70%"}}>
                      <div className={style.titletxt}>
                        pratiksaha patil
                      </div>
                      <div className={style.titletxt}>
                      Sangli            
                      </div>
                    </Typography>
                    <Typography style={{width:"30%",display:"flex",justifyContent:"end"}}>
                    <div className={style.titletxt}>
                        9685201473
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} className={style.txtcontainer}>
                         <div className={style.titlehead}>
                            Title:
                         </div>
                         <div className={style.titletxt}>
                         mangoes
                         </div>
                        </Grid>
                        <Grid item xs={12} md={12} className={style.txtcontainer}>
                         <div className={style.titlehead}>
                            Message:
                         </div>
                         <div className={style.titletxt}>
                         mangoes
                         </div>
                        </Grid>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })}
                </Grid>
            </Grid>
        </Grid>
    </div>
  )
}

export default MobileAdminEnquiry