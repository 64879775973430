import { Divider, Grid } from '@mui/material'
import React from 'react'
import Appbar from '../Appbar/Appbar'
import Footer from '../Footer/Footer'
import style from "./ProductVerieties.module.css"
import Slider from '../Banner/Slider'
import img1 from "../../Assets/product4.png"
import img2 from "../../Assets/product5.png"
import img3 from "../../Assets/product3.jpg"
import img4 from "../../Assets/product6.png"
import { useNavigate } from 'react-router-dom'
import IconComponenet from '../HomePage/IconComponenet'

const Productcard = [
    {
        pname:"Hapus Mangoes",
        imgpath: img2,
        price:"59.00",
        pdesc:"Each box is brimming with a bountiful harvest of 9 to 12 Hapus mangoes, boasting the distinct flavor profile of Ratnagiri Alphonso Mango. Renowned for its majestic presence, this fruit stands as the epitome of excellence among Indian mango varieties. Its enchanting aroma and unparalleled sweetness, coupled with its smooth, fiberless flesh, offer an exquisite culinary experience. Thriving predominantly in the verdant landscapes of Maharashtra, Ratnagiri, and Devgad, we meticulously source our Hapus mangoes exclusively from these revered regions. Upon reaching full ripeness, it releases an aromatic fruity fragrance that permeates the surroundings. Regarded as the most expensive cultivar among all mango varieties, the Ratnagiri Alphonso Mango stands as a symbol of premium quality and unparalleled taste."
    },
    {
        pname:"Indian Alphonso Mangoes",
        imgpath: img3,
        price:"60.00",
        pdesc:"Alphonso Mango, fondly referred to as Hapus, holds the esteemed title of the king of fruits, widely acclaimed across India. Renowned for its majestic presence, this fruit stands as the epitome of excellence among Indian mango varieties. Its enchanting aroma and unparalleled sweetness, coupled with its smooth, fiberless flesh, offer an exquisite culinary experience. Thriving predominantly in the verdant landscapes of Maharashtra, Karnataka, and Gujarat, we meticulously source our Alphonso Mangoes exclusively from these revered regions. Each box is brimming with a bountiful harvest of 09 to 12 fruits, embodying the essence of premium quality and unrivaled flavor.,"
    },
    {
        pname:"Banginapalli Mangoes",
        imgpath: img1,
        price:"69.00",
        pdesc:"Our Banginapalli mangoes are sourced from the top mango-growing regions of Andhra Pradesh. This widely cherished variety features a lovely yellow skin with a subtle crimson hue. Known for their enticing aroma and uniform, large, elongated shape, these mangoes are meticulously inspected prior to sourcing to ensure exceptional quality and a smooth, juicy taste. Loaded with vitamins and minerals, Banginapalli mangoes are highly popular and can be utilized in a diverse range of dishes and desserts or enjoyed fresh.Each box is brimming with a bountiful harvest of 09 to 12 fruits, embodying the essence of premium quality and unrivaled flavor."
    },
    {
        pname:"Kesar Mangoes",
        imgpath: img4,
        price:"50.00",
        pdesc:"The Kesar mango stands out as one of India's most renowned varieties, celebrated for its exceptional sweetness and often hailed as the 'Queen of Mangoes'. Primarily cultivated in the states of Maharashtra and Gujarat, this variety is prized for its resilience, making it an optimal choice for transportation over long distances. Typically, each box contains 09 to 12 fruits."
    },
  ];

const ProductVarieties = () => {
    const navigate = useNavigate();
  return (
    <div>
        <Grid container spacing={0} className={style.productmaincon}>
            <Grid item xs={12} md={12}>
                <Appbar />
            </Grid>
            <Grid item xs={12} md={12}>
          <Slider />
        </Grid>
        <Grid item xs={12} md={10}>
            <Grid container spacing={2} className={style.productsub}>
                <Grid item xs={12} md={12}>
                <div className={style.heading}>Mango Varieties</div>
                </Grid>
                {Productcard?.map((element, index) => {
            return(  
                <Grid item xs={12} md={12} sx={{cursor:"pointer"}}
                // onClick={()=>{
                //     navigate("/productdetail")
                // }}
                >
               <Grid container spacing={1}>
               <Grid item xs={12} md={4} >
                <div className={style.prodimgcon}
              
                >
                    <img src={element?.imgpath} className={style.prodimg}/>
                </div>
            </Grid>
                <Grid item xs={12} md={8} className={style.proddetailcon}>
                    <div className={style.prodnamesty}>{element?.pname}</div>
                    <div className={style.prodprice}>${element?.price}</div>
                    <div className={style.prodinfo}>{element?.pdesc}</div>
                    <Divider />
                </Grid>
               </Grid>
                </Grid>
            )
                })}
            </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
                <IconComponenet />
            </Grid>
        </Grid>
    </div>
  )
}

export default ProductVarieties