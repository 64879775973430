import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter,Routes,Route, unstable_HistoryRouter as Router, useLocation} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import UserContextProvider from './Context/UserContext';
import HomePage from './Component/HomePage/HomePage';
import ProductCard from './Component/Product/ProductCard';
import AboutUs from './Component/AboutUs/AboutUs';
import ContactUs from './Component/ContactUs/ContactUs';
import ProductVarieties from './Component/ProductVarieties/ProductVarieties';
import ProductDetail from './Component/ProductDetail/ProductDetail';
import AdminLogin from './Component/AdminLogin/AdminLogin';
import AdminOrderList from './Component/AdminOrderList/AdminOrderList';
import AdminEnquiry from './Component/AdminEnquiry/AdminEnquiry';
import { Provider } from 'react-redux';
import Store from './API/Store/Store';
import HomePageDesign from './Component/HomePage/HomePageDesign';
import Testomonial from './Component/Testomonial/Testomonial';
import MobileAdminEnquiry from './Component/AdminEnquiry/MobileAdminEnquiry';
import MobileAdminOrder from './Component/AdminOrderList/MobileAdminOrder';
import VideoComponent from './Component/VideoComponent/VideoComponent';
import OrderBook from './Component/OrderBook/OrderBook';
import EmailPage from './Component/EmailPage/EmailPage';
import OrderBookEmail from './Component/EmailPage/OrderBookEmail';
import MaintenancePage from './Component/MaintenancePage/MaintenancePage';
import AdminOrderGenrate from './Component/AdminOrderGenrate/AdminOrderGenrate';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
  <React.StrictMode>
    <BrowserRouter>
    <UserContextProvider>
    <ScrollToTop />
    <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/productcard" element={<ProductCard />} />
    <Route path="/aboutus" element={<AboutUs />} />
    <Route path="/contactus" element={<ContactUs />} />
    <Route path="/verieties" element={<ProductVarieties />} />
    <Route path="/productdetail" element={<ProductDetail />} />
    <Route path="/admin" element={<AdminLogin />} />
    <Route path="/adminorder" element={<AdminOrderList />} />
    <Route path="/enquiry" element={<AdminEnquiry />} />
    <Route path="/order" element={<HomePageDesign />} />
    <Route path="/testomonial" element={<Testomonial />} />
    <Route path="/mobenquiry" element={<MobileAdminEnquiry />} />
    <Route path="/moborder" element={<MobileAdminOrder />} />
    <Route path="/video" element={<VideoComponent />} />
    <Route path="/orderbook" element={<OrderBook />} />
    <Route path="/email" element={<EmailPage />} />
    <Route path="/orderemail" element={<OrderBookEmail />} />
    <Route path="/maintanace" element={<MaintenancePage />} />
    <Route path="/createorder" element={<AdminOrderGenrate />} />

    </Routes>
    </UserContextProvider>
    </BrowserRouter>
    </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
