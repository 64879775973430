import React from 'react'

const OrderBookEmail = () => {
    const bgcolor = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // textAlign: "center",
      };
      const bgImg = {
        width: "100%",
       height: window.innerWidth <= 768 ? "20vh" : "25vh",
      };
    
      const box = {
        boxShadow: "1px 1px 1px 1px rgba(12,12,12,0.2)",
        borderRadius: "5px",
        marginTop: "10px",
        // padding: "20px",
        width: window.innerWidth <= 768 ? "90%" : "30%",
      };
      const subboxcon = {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      };
      const iconcon = {
        height:"20px",
        width:"20px",
        backgroundColor:"black",
        borderRadius:"90%",
        color:"white",
        // padding:"2px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
      }
      const titlecolor = {
        color: "black",
        fontSize: 14,
      };
      const productdetail = {
        color: "black",
        fontSize: 14,
        fontWeight: 600,
      };
    
      const heading = {
        color: "black",
        fontSize: 20,
        fontWeight: 600,
      };
      const headingtxt = {
        color: "black",
        fontSize: 17,
        fontWeight: 600,
      };
      const textcontainer = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: "5px",
        textAlign: "center",
      };
      const text = {
        color: "black",
        fontSize: 16,
        fontWeight: 600,
      };
      const productcnn={
        display: "flex",
        justifyContent:"space-between",
      }
      const divider = {
        width:"100%",
        borderTop: "2px solid #bbb",
      }


  return (
    <>
      <div style={bgcolor}>
        <div style={box}>
          <img
            style={bgImg}
            src="https://cms.araiindia.com/MediaFiles/contactusbanner_1073.png"
          />
          <div style={subboxcon}>
            <div style={textcontainer}>
                <div style={iconcon}>&#10003;</div>
              <div style={heading}>Your order is confirmed!</div>
            </div>
            <div style={headingtxt}>Order Details:</div>
            <div style={productcnn}>
                <div style={productdetail}>Product Name:</div>
                <div style={titlecolor}>Hapus Mangoes</div>
            </div>
            <div style={productcnn}>
                <div style={productdetail}>Price:</div>
                <div style={titlecolor}>$60.00</div>
            </div>
            <div style={productcnn}>
                <div style={productdetail}>Quantity:</div>
                <div style={titlecolor}>2Box</div>
            </div>
            <div style={productcnn}>
                <div style={productdetail}>Total Price:</div>
                <div style={titlecolor}>$120.00</div>
            </div>
            <div style={divider}></div>
            <div style={productcnn}>
                <div style={headingtxt}>Total</div>
                <div style={productdetail}>$240.00</div>
            </div>
          </div>
        </div>  
      </div>
    </>
  )
}

export default OrderBookEmail