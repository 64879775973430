import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import style from "./Home.module.css"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const IconComponent = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScroll(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const redirectToWhatsApp = () => {
    // Phone number and message
    const phoneNumber = '919096261991'; // Replace with the desired phone number
    const message = encodeURIComponent('hi'); // Encode the message

    // Construct the WhatsApp Web URL with phone number and message parameters
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp Web in a new window
    window.open(whatsappUrl, '_blank');
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className={style.iconposition}>
          <div className={style.iconcontainer} onClick={(e)=>{redirectToWhatsApp()}}>
            <WhatsAppIcon style={{ fontSize: "30px", color: "white" }} />
          </div>
          {showScroll && (
            <div className={style.arrowiconcontainer} onClick={scrollToTop}>
              <KeyboardArrowUpIcon style={{ fontSize: "40px", color: "white" }} />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default IconComponent;
