import { Grid } from '@mui/material'
import React from 'react'
import Appbar from '../Appbar/Appbar'
import Slider from '../Banner/Slider'
import Footer from '../Footer/Footer'
import style from "./ProductDetail.module.css"
import img2 from "../../Assets/product5.png"
import IconComponenet from '../HomePage/IconComponenet'

const ProductDetail = () => {
  return (
    <div>
      <Grid container spacing={0} className={style.productmaincon}>
            <Grid item xs={12} md={12}>
                <Appbar />
            </Grid>
            <Grid item xs={12} md={12}>
          <Slider />
        </Grid>
        <Grid item xs={12} md={10}>
            <Grid container spacing={2} className={style.productsub}>
                <Grid item xs={12} md={12}>
                  <div className={style.heading}>
                  Alphonso Mangoes
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                <div className={style.prodimgcon}>
                    <img src={img2} className={style.prodimg}/>
                </div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className={style.prodinfo}>Alphonso Mango, commonly known as Hapus, is often touted to be the king of fruits. This majestic fruit is by far the most sought after variety of Indian Mangoes. Alphonso Mango is very fragrant and sweet and the flesh is fibreless.</div>
                      <div className={style.prodinfo}>Mangoes will be ready/almost ready to eat and can last for about 3-4 days from the date they are made available for pick up. Once fully ripen they can be stored in a fridge to prolong the shelf life.</div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className={style.prodinfohead}>Important information about Alphonso mango:</div>
                      <div className={style.prodinfo}>The weight range specified is a guideline and gives the range when the fruit is being packed. There can be a large variation in the weight of the fruit depending upon many factors such as the rate of ripening, the nature of the fruit etc. Please note that mango can lose weight daily at the rate of 2 to 5 percent.</div>
                      <div className={style.prodinfo}>## Image is for illustration purpose ONLY.</div>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
                <IconComponenet />
            </Grid>
        </Grid>
    </div>
  )
}

export default ProductDetail