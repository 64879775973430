import { createSlice } from "@reduxjs/toolkit";
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import {
  callAjax,
  formatDatetime_DMY,
  formatDatetime_YMD,
  getMonthName,
} from "../../MIS/Global";
import { setAlertData } from "./alertReducer";

const ContactReducer = createSlice({
  name: "product",
  initialState: {
    contact: {},
    contactList: [],
  },

  reducers: {
    setContact(state, action) {
      state.contact = {
        ...state.contact,
        [action.payload.name]: action.payload.value,
      };
    },
    setContactList(state, action) {
        state.contactList = action.payload;
      },
      setBulkContact(state, action) {
        state.contact = action.payload;
      },
  },
});

export const { setContact,setContactList,setBulkContact } =
ContactReducer.actions;
export default ContactReducer.reducer;

export const actionConfirm = (data) => {
  return function actionData(dispatch) {
    if (data === "save") {
      dispatch(
        setConfirmDialogData({
          type: data,
          heading: "Confirm",
          msg: "Do you want to save this record?",
          yeslbl: "Yes",
          nolbl: "No",
          openclose: true,
        })
      );
    }else if(data === "del"){
     dispatch(
        setConfirmDialogData({
            type: data,
            heading: "Confirm",
            msg: "Do you want to delete this record?",
            yeslbl: "Yes",
            nolbl: "No",
            openclose: true,
        })
        );
    }
  };
};

export const handleContactChange = (e, name) => {
    return function actionData(dispatch,getState) {
      const { contact } = getState().contact; // Get current contact object from state
      let updatedContact = { ...contact };
       if (e && e.target) {
        dispatch(setContact({ name: e.target.name, value: e.target.value }));
      }
    dispatch(setContact(updatedContact)); 
    };
  };


export const SaveContact = (data) => {
  return async function actionData(dispatch) {
    try {
      const { fname, lname, ...otherData } = data; 

      const uname = `${fname} ${lname}`.trim(); 
      const edate = new Date();
      const contactdata = { ...otherData, uname, edate }; 
        // alert(JSON.stringify(contactdata))
      let ret = await callAjax("enquirysave", contactdata);
      if (ret.code == 200 && ret.data?.[0]?.dt > 0) {
        dispatch(
          setAlertData({
            msg: "Contact save successfully",
            code: 200,
            open: true,
          })
        );
        dispatch(setBulkContact({}));
        dispatch(getContact());
      } 
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
    dispatch(setRetAnswer({ resans: false }));
  };
};


export const getContact = (data) => {
    return async function actionData(dispatch) {
      try {
        let ret = await callAjax("searchenquiry", data);
        // alert(JSON.stringify(ret))
        if (ret.code == 200) {
          dispatch(setContactList(ret?.data));
        }
      } catch (error) {
        dispatch(
          setAlertData({
            msg: "Something went wrong! Please try again",
            code: 100,
            open: true,
          })
        );
      }
    };
  };

export const EmailContactSend = (data) => {
    return async function actionData(dispatch) {
      try {     
          // alert(JSON.stringify(contactdata))
        let ret = await callAjax("enquiryemail", data);
        if (ret.code == 200) {
          dispatch(
            setAlertData({
              msg: "Message send successfully",
              code: 200,
              open: true,
            })
          );
          dispatch(setBulkContact({}));
         } 
      } catch (error) {
        dispatch(
          setAlertData({
            msg: "Something went wrong! Please try again",
            code: 100,
            open: true,
          })
        );
      }
      dispatch(setRetAnswer({ resans: false }));
    };
  };